<h3>Permiso de Venta de Granos</h3>
<h4>Términos y Condiciones - Permiso de Venta de Granos en la Plataforma</h4>
<agd-alert-box class="alert-box-tos">
    Lee atentamente términos y condiciones para aceptar o rechazar.
</agd-alert-box>
<div class="TOS-content">
    <p><b>AGD AGRO</b>
        <br>
        Vigencia desde: 01/07/2024
    </p>
    <p><b>1. Aceptación de los Términos y Condiciones</b><br>
        El Productor, en adelante “El Cliente”, deberá aceptar los presentes Términos y
        Condiciones antes de comenzar a operar en la Plataforma AGD Agro, en adelante “la
        Plataforma”. El uso de la Plataforma implica la aceptación completa y sin reservas de los
        mismos.</p>
    <p><b>2. Consentimiento para las Órdenes de Venta de Granos</b><br>
        Todas las creaciones de órdenes de venta en Plataforma se considerarán realizadas con el
        consentimiento explícito del Cliente. El Cliente podrá generar una orden de venta de
        granos cuando haya “precios de mercado AGD” publicados para la fecha. Por “precios
        de mercado AGD” debe entenderse los precios publicados en la solapa Precios de la
        Plataforma.</p>
    <p><b>3. Monto de la Orden</b><br>
        Una vez generada la orden de venta, la Plataforma le mencionará a El Cliente el monto
        aproximado a recibir por la venta, calculado en base al precio publicado y la cantidad de
        toneladas indicadas para vender. Este cálculo no incluye posibles gastos de acopio,
        impuestos aplicables u otras deducciones que puedan surgir, por lo cual el monto
        aproximado indicado puede variar del importe neto que finalmente se reciba por la venta.
        El Cliente es responsable de verificar el monto final que reciba por la transacción antes
        de ser aprobada la orden de venta por la empresa. Una vez aprobada, El Cliente no tendrá
        derecho a efectuar reclamo alguno por conceptos no tenidos en cuenta.</p>
    <p><b>4. Aprobación del Acopio</b><br>
        Toda orden de venta deberá ser aprobada por la empresa. La empresa no garantiza la
        ejecución de la venta, la misma queda supeditada a la aprobación de la orden por parte
        del acopio correspondiente. Se establece un plazo máximo de 48 hs para la aprobación o
        rechazo por parte de la empresa. El Cliente recibirá una notificación una vez que la orden
        de venta haya sido aprobada o rechazada.</p>
    <p><b>5. Horarios de Operación</b><br>
        El Cliente podrá realizar operaciones de venta únicamente durante días hábiles y en los
        horarios de mercado habilitados por la empresa, los cuales estarán sujetos a
        modificaciones. La empresa informará a los clientes sobre los horarios vigentes y los
        momentos en los cuales se puede operar a través de la Plataforma.
        Se considerarán días hábiles de Lunes a Viernes, excluyendo los feriados y aquellos días
        que puedan verse afectados por eventos de fuerza mayor.</p>
    <p><b>6. Costos del Servicio</b><br>
        Realizar una orden de venta mediante la Plataforma no tendrá costos asociados para el
        Cliente.</p>
    <p><b>7.Usos Prohibidos</b><br>
        El Cliente se compromete a no utilizar la Plataforma para actividades que infrinjan leyes
        aplicables o los presentes Términos y Condiciones. Se prohíbe:<br>
        (i) Actividades fraudulentas, ilegales o sospechosas de serlo.<br>
        (ii) Realizar actos de fraude, estafa o sospechosos de serlo.<br>
        (iii) Intentar descifrar, descompilar u obtener el código fuente de la Plataforma y/o hacer
        ingeniería inversa.<br>
        (iv) Utilizar herramientas para navegar o buscar en la Plataforma diferentes a las
        proporcionadas por la empresa.<br>
        (v) Realizar actos contrarios a la buena fe, moral, buenas costumbres o la ley.</p>
    <p><b>8. Confidencialidad</b><br>
        Ambas partes acuerdan mantener la confidencialidad de toda información intercambiada
        en el marco de la operación. Esta información no podrá ser utilizada con fines distintos a
        los acordados ni ser divulgada a terceros sin autorización previa.
        Desde la aceptación de los Términos y Condiciones hasta por un plazo de tres (3) años
        contados desde la finalización del vínculo, las Partes mantendrán en absoluto secreto y
        máxima confidencialidad todas y cada una de las informaciones y estipulaciones
        contenidas expresa o implícitamente, e intercambiadas mutuamente, y toda y cualquier
        información relacionada con la relación comercial habida entre las partes, Esta obligación
        de confidencialidad reconoce como excepción a la información que adquiera carácter
        público o que por alguna obligación legal o mandato judicial deba darse a conocer.</p>
    <p><b>9. Políticas de Privacidad</b><br>
        La Plataforma tratará los datos personales de El Cliente de acuerdo con la Política de
        Privacidad vigente en AGD. El Cliente autoriza a la Plataforma y a AGD a utilizar dichos
        datos para la prestación de los servicios ofrecidos por la Plataforma y a compartirlos con
        terceros, cuando ello sea necesario para el correcto funcionamiento de la misma.
    <p><b>10. Duración y Actualización de los Términos y Condiciones</b><br>
        La vigencia de los presentes Términos y Condiciones tendrá una duración indefinida. En
        caso de realizarse actualizaciones de los presentes Términos y Condiciones, se harán sin
        notificación previa por lo que será responsabilidad de El Cliente en cada caso chequear
        de tanto en tanto los Términos y Condiciones, atento a que la utilización de la Plataforma
        implica la aceptación de los mismos.</p>
    <p><b>11. Terminación</b><br>
        La empresa podrá terminar la relación comercial en cualquier momento en caso de
        incumplimiento de los presentes Términos y Condiciones por parte de El Cliente
        retirándole, en consecuencia, el permiso de venta de granos.</p>
    <p><b>12. Separabilidad</b><br>
        Si alguna disposición de estos Términos y Condiciones fuera declarada inválida, ilegal o
        inaplicable por cualquier razón por una autoridad administrativa o judicial competente,
        tal disposición se considerará separable y no afectará la validez y aplicabilidad de las
        disposiciones restantes. En tal caso, la disposición inválida o inaplicable será reemplazada
        por una disposición válida y aplicable que se asemeje lo más posible a la intención
        original de la disposición invalidada, dentro de los límites de la ley aplicable.</p>
    <p><b>13. Notificaciones y Domicilios</b><br>
        <b>13.1</b> Todas las notificaciones, comunicaciones y avisos relacionados con estos Términos
        y Condiciones se realizarán por escrito y se considerarán válidas y efectivamente
        entregadas en las siguientes circunstancias:<br>
        a) Cuando se envíen por correo electrónico a la dirección de correo electrónico registrada
        por el Cliente en la Plataforma.<br>
        b) Cuando se publiquen como notificación dentro de la Plataforma, en la sección de
        notificaciones del usuario.<br>
        <b>13.2</b> Las notificaciones se considerarán recibidas:<br>
        a) En el caso de correos electrónicos, al día hábil siguiente de su envío, salvo que se reciba
        una notificación de fallo en la entrega.<br>
        b) En el caso de notificaciones en la Plataforma, al día hábil siguiente de su publicación.<br>
        <b>13.3</b> Es responsabilidad del Cliente mantener actualizada su información de contacto en
        la Plataforma, incluyendo su dirección de correo electrónico.<br>
        <b>13.4</b> La empresa podrá, a su discreción, utilizar métodos adicionales de notificación,
        como mensajes de texto o correo postal, pero estos serán complementarios y no sustituirán
        los métodos oficiales establecidos en esta cláusula.<br>
        <b>13.5</b> Para efectos legales, La empresa constituye domicilio en Adrián Pascual Urquia 149,
        General Deheza, Córdoba, Argentina. El Cliente constituye domicilio en la dirección
        asociada a su CUIT.<br>
        <b>13.6</b> Cualquier cambio en los domicilios deberá ser notificado a la otra parte por los
        medios establecidos en esta cláusula, y surtirá efectos a partir del quinto día hábil
        siguiente a la recepción de dicha notificación.
    </p>
    <p><b>14. Reclamos o Disputas</b><br>
        Cualquier disputa entre el Cliente y la empresa con relación al uso de la Plataforma se
        resolverá directamente entre las partes, de manera amigable, mediante los canales de
        contacto existentes.<br>
        Para el caso de no poder resolverse amigablemente, se pacta la jurisdicción de los
        Tribunales Ordinarios de la Ciudad de Río Cuarto, provincia de Córdoba.<br>
        Cualquier diferendo comercial, vinculado con la operación de compraventa de granos
        realizada por medio de la Plataforma, serán resueltas en las formas ya previstas
        contractualmente.</p>


    <div class="aceptacion-checkbox">
        <label> <input tabindex="-1" type="checkbox" id="confirmacion-TOS" [(ngModel)]="aceptado" /> He leído y acepto
            términos y condiciones.</label>
    </div>
</div>
<div class="confirmacion-btn">
    <button class="cancelar" tabindex="-1" (click)="clickRechazar()">Rechazar</button><button tabindex="-1"
        class="aceptar" [mat-dialog-close]="true" [disabled]="!aceptado" (click)="clickAceptarTOS()">Aceptar</button>
</div>