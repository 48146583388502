export const SEUO_BODY = `<p style:"text-align:justify;">El precio es un aproximado basado en el último precio publicado.<br>
                              Los precios pueden sufrir variaciones al momento de la transacción. </p>
                              <p style="color:#97aca9; text-align:justify;">El monto a obtener no contempla gastos del acopio.</p>`;

export const USD_SEUO_BODY = `<p style="color:#707070; text-align:justify">El precio de la mercadería se fija en 
                      Dólares Estadounidenses (USD) por 
                      Tonelada.<br>
                      La suma total de dólares estadounidenses convenida como precio será convertida para su pago en 
                      los plazos acordados a pesos o a la moneda nacional de curso legal vigente en el momento de efectuar el pago, 
                      sobre la base del tipo de cambio comprador establecido por el Banco de la Nación Argentina o el que resultare 
                      vigente para la liquidación de divisas de operaciones de exportación de granos, correspondiente al día hábil 
                      cambiario anterior de la fecha de la liquidación. Del precio así calculado se deducirá la suma de USD (Dólares 
                      Estadounidenses a convenir) por tonelada en concepto de flete por mercadería descargada en la planta de recepción 
                      como COMPRADORA.</p>`;


